import { Request } from '@/http/request'

// 用户列表
export function userList(parameter: any){
  return Request.axiosInstance.post('/open/voice-user-check/list', parameter)
}
// 用户列表
export function esUserList(parameter: any){
  return Request.axiosInstance.post('/open/es-user-check/list', parameter)
}
// 管理员列表
export function adminList(parameter: any){
  return Request.axiosInstance.post('/open/voice-check/list', parameter)
}
// es管理员列表
export function esAdminList(parameter: any){
  return Request.axiosInstance.post('/open/es-check/list', parameter)
}
// 根据检查id进行查询
export function getByCheckId(parameter: any){
  return Request.axiosInstance.post('/open/voice-check-content/get-by-checkId', parameter)
}
// 语音统计列表 
export function voschecks(parameter: any){
  return Request.axiosInstance.post('/open/es-vos-check/list', parameter)
}
// 质检详情
export function vosCheckDetail(parameter: any){
  return Request.axiosInstance.post('/open/es-vos-check/get', parameter)
}
// 线路质检详情
export function lineCheckDetail(parameter: any){
  // params:{id:'123456'}
  return Request.axiosInstance.post('/open/es-check/get', parameter)
}
// 质检已读
export function vosRead(parameter: any){
  return Request.axiosInstance.post('/open/voice-check/read', parameter)
}
// 质检已读
export function esCheckNext(parameter: any){
  return Request.axiosInstance.post('/open/es-check/next', parameter)
}
// 获取一段时间内的已读情况
export function voiceCheckProgressChart(parameter: any){
  return Request.axiosInstance.post('/open/voice-check/progress-chart', parameter)
}
// 获取报备行业
export function reportIndustry(parameter: any){
  return Request.axiosInstance.post('/pvm/api/lineData/query-industry-text', parameter)
}
// 质检记录已读
export function esCheck(parameter: any){
  return Request.axiosInstance.post('/open/es-check/check', parameter)
}
// vos质检已读
export function vosCheck(parameter: any){
  return Request.axiosInstance.post('/open/vos-checks/check', parameter)
}
// vos质检审核
export function vosAudit(parameter: any){
  return Request.axiosInstance.post('/open/vos-checks/audit', parameter)
}
// vos质检上一条、下一条
export function vosCheckNext(parameter: any){
  return Request.axiosInstance.post('/open/es-vos-check/next', parameter)
}
export default { reportIndustry,userList, adminList, getByCheckId, voschecks, vosRead, esAdminList, esUserList,
  esCheckNext,voiceCheckProgressChart, esCheck, vosCheck, vosAudit, vosCheckNext
}

import {isRef} from "vue";

export default {
    isEmpty(object) {
        if (object == null || object == undefined) return true;
        let copy = object;
        if (isRef(object)) {
            copy = object.value;
        }
        switch (typeof copy) {
            case 'undefined':
                return true;
            case 'string':
                if (copy.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
                break;
            case 'boolean':
                return false;
            case 'number':
                return Number.isNaN(copy);
            case 'object':
                if (copy instanceof Date) {
                    return false;
                }
                if (null === copy || copy.length === 0) return true;
                for (let i in copy) {
                    return false;
                }
                return true;
        }
        return false;
    },
}
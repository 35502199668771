/**
 * 安全地调用Promise，使用catch截获异常，如为异常返回空对象
 * 主要解决由于以前的代码（网络请求）没有捕获异常，而导致不能使用 async await
 * @param promise Promise或方法
 * @returns {Promise<unknown>}
 */
export const safePromise = function (promise) {
    return new Promise((resolve, reject) => {
        if (promise && promise instanceof Promise) {
            promise.then(res => {
                resolve(res);
            }).catch(e => {
                console.error('safePromise Promise e', e);
                resolve(e || {});
            })
        } else if (promise && typeof promise === 'function') {
            try {
                resolve(promise());
            } catch (e) {
                console.error('safePromise function e', e);
                resolve(e || {});
            }

        } else {
            try {
                resolve(promise);
            } catch (e) {
                console.error('safePromise e', e);
                resolve(e || {});
            }
        }
    })
}